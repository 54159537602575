<template>
  <div>
    <v-card flat>
    <v-card-text class="py-0 my-0 mt-1 pt-2"  style="height: 100%">
      <v-row no-gutters>
        <v-col cols="6" class="pb-0">
          <v-chip outlined class="px-0" style="border-left: none">
            <v-chip outlined class="px-0" style="border-left: none">
              <v-chip outlined class="px-0" style="border-left: none">
                <v-chip outlined class="pl-3 pr-0">
                  <span class="mr-3" style="opacity: 0.7">Assigned User:</span>
                  <v-menu
                    bottom
                    offset-y
                    :close-on-content-click="false"
                    v-model="userModal"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-avatar color="secondary" size="32" class="mr-2">
                          <span v-if="!selectedUser.avatar" class="text-h7">{{
                            $Format.avatarAbbreviation(selectedUser.fullName)
                          }}</span>
                          <img
                            v-else
                            :src="selectedUser.avatar"
                            referrerpolicy="no-referrer"
                          />
                        </v-avatar>
                        <span>{{ selectedUser.fullName }}</span>
                        <v-icon small class="ml-2"
                          >arrow_drop_down</v-icon
                        >
                      </div>
                    </template>
                    <v-card color="greyBase">
                      <v-card-text class="pa-2">
                        <v-text-field
                          v-model="searchUsers"
                          prepend-inner="search"
                          dense
                          hide-details
                          autofocus
                          outlined
                          label="Search Users"
                          style="width: 275px"
                        >
                        </v-text-field>
                        <div style="max-height: 40vh; overflow-y: scroll">
                          <v-list dense v-if="docsUsersFiltered.length > 0">
                            <v-list-item
                              dense
                              v-for="user in docsUsersFiltered"
                              :key="user.id"
                              @click="changeUser(user)"
                              style="width: 275px"
                            >
                              <v-list-item-avatar>
                                <v-avatar color="secondary" size="32">
                                  <span v-if="!user.avatar" class="text-h7">{{
                                    $Format.avatarAbbreviation(user.fullName)
                                  }}</span>
                                  <img
                                    v-else
                                    :src="user.avatar"
                                    referrerpolicy="no-referrer"
                                  />
                                </v-avatar>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  user.fullName
                                }}</v-list-item-title>
                                <v-list-item-subtitle>{{
                                  user.emailAddress
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                  <v-tooltip>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        small
                        @click="pinUser()"
                        :disabled="
                          pinUsersDisabled &&
                          !pinnedUsers.find((x) => x.id == selectedUser.id)
                        "
                      >
                        <v-icon
                          small
                          :color="
                            pinnedUsers.find((x) => x.id == selectedUser.id)
                              ? 'tertiary'
                              : ''
                          "
                          >push_pin</v-icon
                        >
                      </v-btn>
                    </template>
                    <span v-if="!pinUsersDisabled">Pin User</span>
                    <span v-if="pinUsersDisabled">Max Pinned Users</span>
                  </v-tooltip>
                </v-chip>
                <div
                  v-if="pinnedUsers[0] && pinnedUsers[0].id != selectedUser.id"
                  class="ml-3 mr-3"
                  @click="changeUser(pinnedUsers[0])"
                >
                  <v-avatar color="secondary" size="32" class="mr-2">
                    <span v-if="!pinnedUsers[0].avatar" class="text-h7">{{
                      $Format.avatarAbbreviation(pinnedUsers[0].fullName)
                    }}</span>
                    <img
                      v-else
                      :src="pinnedUsers[0].avatar"
                      referrerpolicy="no-referrer"
                    />
                  </v-avatar>
                  <span>{{ pinnedUsers[0].fullName.split(" ")[0] }}</span>
                </div>
              </v-chip>
              <div
                v-if="pinnedUsers[1] && pinnedUsers[1].id != selectedUser.id"
                class="ml-3 mr-3"
                @click="changeUser(pinnedUsers[1])"
              >
                <v-avatar color="secondary" size="32" class="mr-2">
                  <span v-if="!pinnedUsers[1].avatar" class="text-h7">{{
                    $Format.avatarAbbreviation(pinnedUsers[1].fullName)
                  }}</span>
                  <img
                    v-else
                    :src="pinnedUsers[1].avatar"
                    referrerpolicy="no-referrer"
                  />
                </v-avatar>
                <span>{{ pinnedUsers[1].fullName.split(" ")[0] }}</span>
              </div>
            </v-chip>
            <div
              v-if="pinnedUsers[2] && pinnedUsers[2].id != selectedUser.id"
              class="ml-3 mr-3"
              @click="changeUser(pinnedUsers[2])"
            >
              <v-avatar color="secondary" size="32" class="mr-2">
                <span v-if="!pinnedUsers[2].avatar" class="text-h7">{{
                  $Format.avatarAbbreviation(pinnedUsers[2].fullName)
                }}</span>
                <img
                  v-else
                  :src="pinnedUsers[2].avatar"
                  referrerpolicy="no-referrer"
                />
              </v-avatar>
              <span>{{ pinnedUsers[2].fullName.split(" ")[0] }}</span>
            </div>
          </v-chip>
        </v-col>
        <v-col cols="6" class="pb-0 d-flex justify-end">
          <v-chip
            class="pr-1 pl-2"
            outlined
            style="border: none"
            @click="(viewUnprocessed = !viewUnprocessed), readyFilesKey++"
          >
            <span class="mr-2" style="font-size: 12px">Unprocessed Files:</span>
            <v-chip pill outlined small>
              <span v-if="!loadingCounts">{{ readyFiles.count }}</span>
              <v-progress-circular
                indeterminate
                size="20"
                width="2"
                color="primary"
                v-if="loadingCounts"
              ></v-progress-circular>
            </v-chip>
          </v-chip>
          <v-chip
            class="pr-1 pl-2"
            outlined
            style="border: none"
            @click="viewNotReady = !viewNotReady"
          >
            <span class="mr-2" style="font-size: 12px"
              >Not Ready to Invoice:</span
            >
            <v-chip pill outlined small>
              <span v-if="!loadingCounts">{{ notReadyInvoice.count }}</span>
              <v-progress-circular
                indeterminate
                size="20"
                width="2"
                color="primary"
                v-if="loadingCounts"
              ></v-progress-circular>
            </v-chip>
          </v-chip>
          <v-chip
            class="pr-1 pl-2"
            outlined
            style="border: none"
            @click="openFilesActive()"
          >
            <span class="mr-2" style="font-size: 12px">Open Files:</span>
            <v-chip pill outlined small>
              <span v-if="!loadingCompletedFiles && !loadingFiles">{{
                openFiles.length
              }}</span>
              <v-progress-circular
                indeterminate
                size="20"
                width="2"
                color="primary"
                v-if="loadingCompletedFiles || loadingFiles"
              ></v-progress-circular>
            </v-chip>
          </v-chip>
        </v-col>
      </v-row>
      <div class="d-flex align-center">
        <div class="d-flex align-center">
          <v-chip
            small
            :outlined="!fivePlus"
            color="redPop"
            @click="switchFilter('5+')"
            class="px-1 mr-2"
          >
            <span class="mr-2">5+ Days:</span>
            <v-chip
              x-small
              class="px-2"
              :color="!fivePlus ? 'redPop' : 'white'"
              :style="
                fivePlus ? { color: 'var(--v-redPop-base) !important' } : ''
              "
              >{{ sumFiles("5+") }}</v-chip
            >
          </v-chip>
          <v-chip
            small
            :outlined="!threeToFour"
            color="#dfaa49"
            @click="switchFilter('3-4')"
            class="px-1 mr-2"
          >
            <span class="mr-2">3-4 Days:</span>
            <v-chip
              x-small
              class="px-2"
              :color="!threeToFour ? '#dfaa49' : 'white'"
              :style="threeToFour ? { color: '#dfaa49 !important' } : ''"
              >{{ sumFiles("3-4") }}</v-chip
            >
          </v-chip>
          <v-chip
            small
            :outlined="!zeroToTwo"
            color="tertiary"
            @click="switchFilter('0-2')"
            class="px-1 mr-2"
          >
            <span class="mr-2">0-2 Days:</span>
            <v-chip
              x-small
              class="px-2"
              :color="!zeroToTwo ? 'tertiary' : 'white'"
              :style="
                zeroToTwo ? { color: 'var(--v-tertiary-base) !important' } : ''
              "
              >{{ sumFiles("0-2") }}</v-chip
            >
          </v-chip>
          <v-chip
            small
            :outlined="!past"
            color="grey"
            @click="switchFilter('past')"
            class="px-1 mr-2"
          >
            <span class="mr-2">No ATD/ATA</span>
            <v-chip
              x-small
              class="px-2"
              :color="!past ? 'grey' : 'white'"
              style="color: var(--v-component-base) !important"
              >{{ sumFiles("past") }}</v-chip
            >
          </v-chip>
          <v-chip
            small
            :outlined="!zeroToThree"
            color="blue"
            @click="switchFilter('Arriving')"
            class="px-1 mr-2"
          >
            <span class="mr-2">Arriving</span>
            <v-chip
              x-small
              class="px-2"
              :color="!zeroToThree ? 'blue' : 'white'"
              :style="zeroToThree ? { color: 'blue' } : ''"
              >{{ sumFiles("Arriving") }}</v-chip
            >
          </v-chip>
        </div>
        <v-spacer></v-spacer>
        <div
          class="px-0 d-flex justify-center align-center"
          style="width: 550px"
        >
          <v-chip
            class="mr-1 searchChipWrap"
            :class="{
              shrinkChipPlus: showBulkUpdate && filterHovered,
              slightShrink: showBulkUpdate && !filterHovered,
              shrinkChip: filterHovered && !showBulkUpdate,
              shrinkMore: chipHovered,
            }"
          >
            <v-text-field
              placeholder="Search"
              class="mb-1 searchInput"
              hide-details
              rounded
              clearable
              dense
              style="margin-top: 2px"
              v-model="search"
            ></v-text-field>
          </v-chip>
          <v-chip
            class="pa-1 mr-1"
            :class="refreshing ? 'rotateRefresh' : ''"
            @click="refresh"
          >
            <v-icon>refresh</v-icon>
          </v-chip>
          <v-chip
            class="mr-1 bulkUpdate"
            color="edit"
            v-if="showBulkUpdate"
            :disabled="!canBulkUpdate"
            @click="bulkUpdateModal = !bulkUpdateModal"
            @mouseover="chipHovered = true"
            @mouseleave="chipHovered = false"
          >
            <v-icon small left>keyboard_double_arrow_up</v-icon>
            <span class="bulkUpdateText">Bulk Update</span>
          </v-chip>
          <v-chip
            @click="showCompletedTrigger()"
            :color="showCompleted ? 'tertiary' : ''"
            class="chipCompleted mr-1"
            :loading="showCompleted && loadingFiles"
            @mouseover="chipHovered = true"
            @mouseleave="chipHovered = false"
            v-if="!showBulkUpdate"
          >
            <v-icon small left>{{
              showCompleted ? "done_all" : "remove_done"
            }}</v-icon>
            <span class="textCompleted">Show Completed</span>
          </v-chip>
          <v-chip
            color="redPop"
            class="filterHover"
            @click="resetFilters"
            v-if="filterActive"
            @mouseover="filterHovered = true"
            @mouseleave="filterHovered = false"
          >
            <v-icon small left>filter_alt_off</v-icon>
            <span class="textFilters">Clear Filters</span>
          </v-chip>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex justify-end align-center">
          <v-chip
            class="pr-1 pl-2"
            :outlined="
              !(
                filterSetting['status'] &&
                filterSetting['status'].values.includes('Rejected')
              )
            "
            :style="
              !(
                filterSetting['status'] &&
                filterSetting['status'].values.includes('Rejected')
              )
                ? { border: 'none' }
                : { 'background-color': '#eb403456' }
            "
            @click="switchFilter('rejected')"
          >
            <span class="mr-2" style="font-size: 12px">Rejected:</span>
            <v-chip small pill color="danger">{{
              sumDocument("status", "Rejected")
            }}</v-chip>
          </v-chip>
          <v-chip
            class="pr-1 pl-2"
            :outlined="
              !(
                filterSetting['kpiCategory'] &&
                filterSetting['kpiCategory'].values.includes('OVERDUE')
              )
            "
            :style="
              !(
                filterSetting['kpiCategory'] &&
                filterSetting['kpiCategory'].values.includes('OVERDUE')
              )
                ? { border: 'none' }
                : { 'background-color': '#e371696c' }
            "
            @click="switchFilter('OVERDUE')"
          >
            <span class="mr-2" style="font-size: 12px">Overdue:</span>
            <v-chip small pill color="redPop">
              <b>{{ sumDocument("kpiCategory", "OVERDUE") }}</b>
            </v-chip>
          </v-chip>
          <v-chip
            class="pr-1 pl-2"
            :outlined="
              !(
                filterSetting['kpiCategory'] &&
                filterSetting['kpiCategory'].values.includes('DANGER')
              )
            "
            :style="
              !(
                filterSetting['kpiCategory'] &&
                filterSetting['kpiCategory'].values.includes('DANGER')
              )
                ? { border: 'none' }
                : { 'background-color': '#dfaa494f' }
            "
            @click="switchFilter('DANGER')"
          >
            <span class="mr-2" style="font-size: 12px">In Danger:</span>
            <v-chip small pill color="#dfaa49">{{
              sumDocument("kpiCategory", "DANGER")
            }}</v-chip>
          </v-chip>
          <v-chip
            class="pr-1 pl-2"
            :outlined="
              !(
                filterSetting['kpiCategory'] &&
                filterSetting['kpiCategory'].values.includes('GOOD')
              )
            "
            :style="
              !(
                filterSetting['kpiCategory'] &&
                filterSetting['kpiCategory'].values.includes('GOOD')
              )
                ? { border: 'none' }
                : { 'background-color': '#007d5c52' }
            "
            @click="switchFilter('GOOD')"
          >
            <span class="mr-2" style="font-size: 12px">Within KPI:</span>
            <v-chip small pill color="tertiary">{{
              sumDocument("kpiCategory", "GOOD")
            }}</v-chip>
          </v-chip>
        </div>
      </div>
      <v-card-text
        v-if="!$vuetify.breakpoint.mobile"
        style="width: 100%; overflow-x: auto"
        class="my-0 mx-0 px-0"
      >
        <div :style="{ width: '100%' }">
          <ag-grid-vue
            groupDisplayType="multipleColumns"
            :animateRows="false"
            :rowGroupPanelShow="'always'"
            :autoSizeStrategy="{
              type: 'fitCellContents',
              defaultMinWidth: 125,
              columnLimits: [
                {
                  colId: 'documentationStatus',
                  minWidth: 120,
                  width: 120,
                },
                {
                  colId: 'fileNumber',
                  minWidth: 180,
                  width: 180,
                },
                {
                  colId: 'document',
                  minWidth: 180,
                  width: 180,
                },
                {
                  colId: 'vesselVoyage',
                  minWidth: 200,
                },
                {
                  colId: 'shipperName',
                  minWidth: 200,
                },
                {
                  colId: 'onBehalfShipperName',
                  minWidth: 200,
                },
                {
                  colId: 'consigneeName',
                  minWidth: 200,
                },
                {
                  colId: 'kpiCategory',
                  minWidth: 50,
                },
              ],
            }"
            style="width: 100%; height: 84vh"
            :enableRangeSelection="true"
            :suppressAggFuncInHeader="true"
            :columnDefs="columns"
            :postProcessPopup="postProcessPopup"
            :rowData="files"
            :sideBar="sidebar"
            :statusBar="statusBar"
            :defaultColDef="defaultColDef"
            :allowContextMenuWithControlKey="true"
            :getContextMenuItems="getContextMenuItems"
            :initialState="initialState"
            :getRowId="getRowId"
            :loadingOverlayComponent="'LoadingOverlay'"
            :loading="true"
            @grid-ready="onGridReady"
            @state-updated="onStateUpdated"
          ></ag-grid-vue>
        </div>
      </v-card-text>
    </v-card-text>
  </v-card>
    <v-dialog
      v-model="viewOpenFiles"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <OpenFiles
          @close="viewOpenFiles = false"
          @filterTable="setFilter"
          :documents="openFiles"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="viewUnprocessed"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <ReadyFiles
          :selectedUser="selectedUser"
          :isDialog="true"
          :opened="viewUnprocessed"
          :filterKey="readyFilesKey"
          :height="'90vh'"
          @close="viewUnprocessed = false"
          @refresh="getCounts()"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="viewNotReady"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <NotReadyToInvoice
          :selectedUser="selectedUser"
          :isDialog="true"
          @close="viewNotReady = false"
          :key="userRefreshKey"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      :persistent="!loadingDocumentDetail"
      v-model="documentModal"
      :width="!loadingDocumentDetail ? '90vw' : '400px'"
      :fullscreen="!loadingDocumentDetail"
    >
      <v-card
        v-if="loadingDocumentDetail"
        style="
          background: rgba(0, 0, 0, 0.1) !important;
          background-color: rgba(0, 0, 0, 0.1) !important;
          border-radius: 16px;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(18px);
          -webkit-backdrop-filter: blur(18px);
          border: 1px solid rgba(255, 255, 255, 0.1);
        "
      >
        <v-card-text>
          <v-container
            style="
              width: 100%;
              height: 100%;
              background: transparent !important;
              background-color: transparent !important;
            "
          >
            <v-row justify="center" align="center" style="height: 100%">
              <v-col cols="12" class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <div class="mt-3" style="font-size: 14px">
                  Loading Document...
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <DocumentModal
        v-else
        @close="documentModal = false"
        :shipment="shipment"
        :item="shipment"
        :actions="actions"
        :bookings="bookings"
        :documentItem="document"
        :tasks="documentTasks"
        :documents="documentTypes"
        :documentTypes="documentTypes"
        :fileNumber="fileNumber"
        :uploads="documentUploads"
        :openKey="openKey"
        @updateDocument="updateDocument"
      />
    </v-dialog>
    <v-dialog v-model="bulkUpdateModal" width="auto">
      <v-card v-if="bulkUpdateType && canBulkUpdate">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{
              bulkUpdateType == "Pending"
                ? "Update Submitted Date"
                : "Update Completed Date"
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon small @click="bulkUpdateModal = false" color="redPop">
            <v-icon small>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <!--Date picker-->
          <v-date-picker v-model="bulkDate" scrollable style="margin: 5px">
            <v-row justify="center" style="margin-top: 4px">
              <v-btn
                :disabled="!bulkDate"
                text
                color="primary"
                :loading="bulkUpdateLoading"
                @click="bulkUpdateDates"
              >
                Submit
              </v-btn>
            </v-row>
          </v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="userAgreement" width="20vw">
      <v-card>
        <v-card-title
          >Disclaimer <v-spacer></v-spacer
          ><v-btn icon @click="userAgreement = false" color="primary"
            ><v-icon>close</v-icon></v-btn
          ></v-card-title
        >
        <v-card-text
          ><p>
            <b><u>Please take note that you are signing away your life</u></b>
            and potentially making someone else's day!
          </p>
          <p>
            By signing this you are saying that we as a company can deduct an
            amount from your salary and pay to a random someone else if
            something went wrong with this shipment.
          </p></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="(userAgreement = false), (agreed = true)"
          >
            I agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
    
    <script>
/* eslint-disable vue/no-unused-components */
import ActionPanel from "./FileComponents/ActionPanel.vue";
import { AgGridVue } from "ag-grid-vue";
import Boolean from "../GridComponents/Boolean.vue";
import CompleteDateCell from "./FileComponents/CompleteDateCell.vue";
import SubmittedDateCell from "./FileComponents/SubmittedDateCell.vue";
import DocumentModal from "@/components/SystemComponents/DocumentModal.vue";
import TableHeader from "../GridComponents/TableHeader.vue";
import ReadyFiles from "./ReadyFiles.vue";
import NotReadyToInvoice from "./NotReadyInvoice.vue";
import kpiCategory from "./DocumentationComponents/KPICategory.vue";
import kpiStatus from "./DocumentationComponents/KPIStatus.vue";
import LoadingOverlay from "../AgGrid/LoadingOverlay.vue";
import OpenFiles from "./OpenFiles.vue";
import OrganisationName from "../GridComponents/Organisation.vue";
import PortCell from "../GridComponents/Port.vue";
import ResponsiblePartyType from "./DocumentationComponents/ResponsibleParty.vue";
import SailedCategory from "./DocumentationComponents/SailedCategory.vue";
import WorkspaceComment from "./DocumentationComponents/WorkspaceComment.vue";
export default {
  components: {
    ActionPanel,
    AgGridVue,
    Boolean,
    CompleteDateCell,
    DocumentModal,
    ReadyFiles,
    NotReadyToInvoice,
    agColumnHeader: TableHeader,
    kpiCategory,
    kpiStatus,
    LoadingOverlay,
    OpenFiles,
    OrganisationName,
    PortCell,
    ResponsiblePartyType,
    SailedCategory,
    SubmittedDateCell,
    WorkspaceComment,
  },
  data: () => ({
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      enableRowGroup: true,
      enableValue: true,
      enablePivot: true,
      flex: 1,
      minWidth: 100,
      cellClass: "d-flex align-center",
      cellStyle: { textAlign: "center", fontSize: "11px" },
    },
    docUsers: [],
    searchUsers: "",
    bulkUpdateType: "",
    selectedUser: null,
    fivePlus: false,
    zeroToThree: false,
    bulkDate: null,
    viewOpenFiles: false,
    userAgreement: false,
    agreed: false,
    bulkUpdateModal: false,
    bulkUpdateLoading: false,
    savingComment: false,
    searchHeaders: {},
    updateOnSelectedKey: 51,
    viewUnprocessed: false,
    chipHovered: false,
    filterHovered: false,
    selectUpdateKey: 0,
    readyFilesKey: 25,
    canBulkUpdate: false,
    showBulkUpdate: false,
    fileRequest: undefined,
    completedFileTimeout: undefined,
    completedFileRequest: undefined,
    refreshing: false,
    fileTimeout: undefined,
    countsTimeout: undefined,
    showSelect: false,
    countsRequest: undefined,
    loadingCounts: false,
    loadingComments: false,
    viewNotReady: false,
    threeToFour: false,
    selectAllCheck: false,
    filteredResults: [],
    showCompleted: false,
    statusFilter: ["OPEN"],
    filteredValues: [],
    bulkUpdateArray: [],
    filters: {},
    zeroToTwo: false,
    past: false,
    openFilesFilter: "",
    rejected: "",
    overdue: "",
    inDanger: "",
    withinKPI: "",
    commentItem: {
      comment: "",
      commentType: "Query",
    },
    shipmentCounts: null,
    searchKey: 0,
    loadingCompletedFiles: false,
    loadingDocumentDetail: false,
    storedFiles: [],
    documentLoading: null,
    document: {},
    documentTypes: [],
    openFiles: [],
    documentTasks: [],
    documentUploads: [],
    actions: [],
    shipment: {},
    bookings: [],
    pinnedUsers: [],
    fileNumber: "",
    openKey: 5000,
    documentModal: false,
    loadingFiles: false,
    readyFiles: { count: 0 },
    updateFilterfield: 0,
    search: "",
    sortDesc: null,
    sortBy: null,
    loading: false,
    files: null,
    notReadyInvoice: {
      data: [],
      count: 0,
    },
    filterActive: false,
    filterSetting: {},
    gridApi: null,
    columns: [
      //   {
      //     headerName: "Actions",
      //     field: "actions",
      //   },
      {
        headerName: "Customer",
        field: "customerName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.customer
            ? {
                name: p.data.customer.name,
                alias: p.data.customer.alias,
                logo: p.data.customer.logo,
                friendlyName: p.data.customer.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "Shipper",
        field: "shipperName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shipper
            ? {
                name: p.data.shipper.name,
                alias: p.data.shipper.alias,
                logo: p.data.shipper.logo,
                friendlyName: p.data.shipper.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "Consignee",
        field: "consigneeName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.consignee
            ? {
                name: p.data.consignee.name,
                alias: p.data.consignee.alias,
                logo: p.data.consignee.logo,
                friendlyName: p.data.consignee.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "File No.",
        field: "fileNumber",
      },
      {
        headerName: "Vessel & Voyage",
        field: "vesselVoyage",
      },
      {
        headerName: "Sailed (Days)",
        field: "daysFromToday",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Sailed Category",
        field: "sailingDayCategory",
        cellRenderer: "SailedCategory",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Days to Arrival",
        field: "arrivingDays",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Arriving",
        field: "arriving",
        hide: true,
        cellRenderer: "Boolean",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Party",
        field: "responsiblePartyType",
        cellRenderer: "ResponsiblePartyType",
        cellRendererParams: {
          disableEdit: true,
        },
      },
      {
        headerName: "Document",
        field: "document",
        cellRenderer: "kpiStatus",
      },
      {
        headerName: "KPI",
        field: "kpiCategory",
        cellRenderer: "kpiCategory",
        aggFunc: "count",
      },
      {
        headerName: "Status",
        field: "status",
        cellRenderer: "WorkspaceComment",
        aggFunc: "count",
      },
      {
        headerName: "Reference",
        field: "reference",
        filter: "agMultiColumnFilter",
      },
      {
        headerName: "Submitted",
        field: "submittedDate",
        cellRenderer: "SubmittedDateCell",
      },
      {
        headerName: "Resubmitted",
        field: "resubmitDate",
      },
      {
        headerName: "Completed",
        field: "completedDate",
        cellRenderer: "CompleteDateCell",
      },
      {
        headerName: "POL",
        field: "portOfLoadValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfLoadValue : null,
            name: p.data ? p.data.portOfLoadCity : null,
          };
        },
      },
      {
        headerName: "POD",
        field: "portOfDischargeValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfDischargeValue : null,
            name: p.data ? p.data.portOfDischargeCity : null,
          };
        },
      },
      {
        headerName: "Destination",
        field: "finalDestinationValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.finalDestinationValue : null,
            name: p.data ? p.data.finalDestinationCity : null,
          };
        },
      },
      {
        headerName: "ETD",
        field: "etd",
      },
      {
        headerName: "ETA",
        field: "eta",
      },
    ],
    headers: [],
    initialState: {},
    searchTimeout: null,
    sidebar: {},
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
    userModal: false,
    userRefreshKey: 300,
  }),
  async created() {
    if (
      localStorage.getItem(
        "documentWorkspace_" + this.$store.state.currentOrg.id
      )
    ) {
      let state = JSON.parse(
        localStorage.getItem(
          "documentWorkspace_" + this.$store.state.currentOrg.id
        )
      );
      if (typeof state === "object" && state !== null) {
        this.initialState = state;
      }
    }
    this.sidebar = {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
        {
          id: "actions",
          labelDefault: "Actions",
          labelKey: "actions",
          iconKey: "chart",
          toolPanel: "ActionPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
          toolPanelParams: {
            updateDocument: this.updateDocument,
            refreshTable :this.refreshTable,
            resetTable: this.resetTable,
            exportExcel: () => {
              this.gridApi.exportDataAsExcel();
            },
            exportCSV: () => {
              this.gridApi.exportDataAsCsv();
            },
          },
        },
      ],
      defaultToolPanel: "",
    };
    if (sessionStorage.getItem("selectedUser")) {
      this.selectedUser = JSON.parse(sessionStorage.getItem("selectedUser"));
    } else {
      this.selectedUser = {
        id: this.$store.state.user.id,
        fullName:
          this.$store.state.user.firstname +
          " " +
          this.$store.state.user.surname,
        emailAddress: this.$store.state.user.email,
        avatar: this.$store.state.user.avatar,
      };
    }
    this.getDocUsers();
    this.getCounts();
  },
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
    // showCompleted: {
    //   immediate: true,
    //   handler(val) {
    //     if (val) {
    //       this.getDocs();
    //     }
    //   },
    // },

    bulkUpdateModal: function (newVal, oldVal) {
      if (newVal == false) {
        this.bulkUpdateArray = [];
      } else {
        this.bulkUpdateArray = this.files
          .filter((x) => x.selected)
          .map((x) => {
            return {
              id: x.id,
              shipmentId: x.shipmentId,
            };
          });
      }
    },
  },
  computed: {
    openFilesCount() {
      let result = 0;
      // let uniqueShipments;
      // if (this.storedFiles) {
      //   uniqueShipments = this.storedFiles.reduce((acc, file) => {
      //     let fileNumber = file.shipment.shipmentFile.fileNumber;
      //     if (!acc[fileNumber]) {
      //       acc[fileNumber] = file.shipment;
      //     }
      //     return acc;
      //   }, {});
      // }
      // if (Object.values(uniqueShipments).length > 0) {
      //   result = Object.values(uniqueShipments).length;
      // }
      if (this.files) {
        result = [
          ...new Set(
            this.files
              .filter((x) => x.documentationStatus === "OPEN")
              .map((x) => x.fileNumber)
          ),
        ].length;
      }
      return result;
    },
    daysCalculated() {
      let result = {
        fivePlus: 0,
        threeToFour: 0,
        zeroToTwo: 0,
        past: 0,
        zeroToThree: 0,
      };
      if (this.filesMap.length > 0) {
        const uniqueShipments = this.filesMap.reduce((unique, item) => {
          return unique.find((x) => x.fileNumber === item.fileNumber)
            ? unique
            : [
                ...unique,
                {
                  fileNumber: item.fileNumber,
                  daysFromToday: item.daysFromToday,
                },
              ];
        }, []);
        for (let i = 0; i < uniqueShipments.length; i++) {
          let days = uniqueShipments[i].daysFromToday;

          if (days < 0) {
            result.past++;
          } else if (days >= 5) {
            result.fivePlus++;
          } else if (days >= 3 && days < 5) {
            result.threeToFour++;
          } else if (days >= 0 && days <= 3) {
            result.zeroToThree++;
            if (days >= 0 && days <= 2) {
              result.zeroToTwo++;
            }
          }
        }
      }
      return result;
    },
    rejectedCount() {
      if (this.filesMap.length > 0) {
        return this.filesMap.filter((x) => x.status == "Rejected").length;
      } else {
        return 0;
      }
    },
    overdueCount() {
      if (this.filesMap.length > 0) {
        return this.filesMap.filter((x) => x.kpiCategory == "OVERDUE").length;
      } else {
        return 0;
      }
    },
    inDangerCount() {
      if (this.filesMap.length > 0) {
        return this.filesMap.filter((x) => x.kpiCategory == "DANGER").length;
      } else {
        return 0;
      }
    },
    withinKPICount() {
      if (this.filesMap.length > 0) {
        return this.filesMap.filter((x) => x.kpiCategory == "GOOD").length;
      } else {
        return 0;
      }
    },

    pinUsersDisabled() {
      return this.pinnedUsers.length == 3;
    },
    hasFilters() {
      let result = false;
      let vals = Object.values(this.filters);
      if (vals.some((x) => x.length > 0)) {
        return true;
      }
      [
        "fivePlus",
        "threeToFour",
        "zeroToTwo",
        "past",
        "zeroToThree",
        "openFilesFilter",
        "rejected",
        "overdue",
        "inDanger",
        "withinKPI",
      ].some((x) => {
        if (this[x]) {
          result = true;
        }
      });
      return result;
    },
    docsUsersFiltered() {
      let users = [];
      if (this.docUsers.length > 0) {
        if (this.searchUsers) {
          users = this.docUsers.filter((user) =>
            user.fullName.toLowerCase().includes(this.searchUsers.toLowerCase())
          );
        } else {
          users = this.docUsers;
        }
      }
      users.sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
      return users;
    },
    filesMap() {
      return this.filteredResults
        .filter((x) => {
          let daysFilter = true;
          if (this.past) {
            daysFilter = x.daysFromToday < 0;
          } else if (this.fivePlus) {
            daysFilter = x.daysFromToday >= 5;
          } else if (this.threeToFour) {
            daysFilter = x.daysFromToday >= 3 && x.daysFromToday < 5;
          } else if (this.zeroToThree) {
            daysFilter = x.daysFromToday >= 0 && x.daysFromToday <= 3;
          } else if (this.zeroToTwo) {
            daysFilter = x.daysFromToday >= 0 && x.daysFromToday <= 2;
          }

          let statusFilter = true;
          if (this.openFilesFilter) {
            statusFilter = x.documentationStatus === "OPEN";
          } else if (this.rejected) {
            statusFilter = x.status === "Rejected";
          } else if (this.overdue) {
            statusFilter = x.kpiCategory === "OVERDUE";
          } else if (this.inDanger) {
            statusFilter = x.kpiCategory === "DANGER";
          } else if (this.withinKPI) {
            statusFilter = x.kpiCategory === "GOOD";
          }

          let searchFilter = true;
          if (this.search) {
            searchFilter = [
              x.fileNumber,
              x.reference,
              x.etd,
              x.eta,
              x.responsiblePartyType,
              x.customerName,
              x.buyerName,
              x.shipperName,
              x.consigneeName,
              x.movementType,
              x.vesselVoyage,
              x.document,
              x.portOfLoadValue,
              x.portOfLoadCity,
              x.finalDestinationValue,
              x.finalDestinationCity,
              x.status,
              x.submittedDate,
              x.resubmitDate,
              x.completedDate,
            ].some(
              (field) =>
                field && field.toLowerCase().includes(this.search.toLowerCase())
            );
          }

          return daysFilter && statusFilter && searchFilter;
        })
        .sort((a, b) => {
          if (this.sortBy) {
            return this.sortDesc
              ? a[this.sortBy] < b[this.sortBy]
                ? 1
                : -1
              : a[this.sortBy] > b[this.sortBy]
              ? 1
              : -1;
          }
          return 0;
        });
    },
  },
  methods: {
    updateDocument(data) {
      let find = this.files.find((x) => x.id == data.id);
      if (find) {
        find = { ...find, ...data };
      }
      const rowNode = this.gridApi.getRowNode(data.id);
      if (rowNode) {
        rowNode.setData(find);
        this.gridApi.refreshCells({ rowNodes: [rowNode] });
      }
    },
    getRowId(params) {
      return params.data.id;
    },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
          {
            name: "View Document",
            icon: '<span class="ag-icon ag-icon-eye" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data && params.node.data.id) {
                this.viewDocument(params.node.data);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          {
            name: "Open File",
            icon: '<span class="ag-icon ag-icon-arrows" unselectable="on" role="presentation"></span>',
            action: () => {
              if (
                params.node &&
                params.node.data &&
                params.node.data.shipmentReference
              ) {
                this.$router.push({
                  path: "/documentation/" + params.node.data.shipmentReference,
                });
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          // {
          //   name: "Open File (New)",
          //   icon: '<span class="ag-icon ag-icon-arrows" unselectable="on" role="presentation"></span>',
          //   action: () => {
          //     if (
          //       params.node &&
          //       params.node.data &&
          //       params.node.data.shipmentReference
          //     ) {
          //       this.$router.push({
          //         path: "/documentation/" + params.node.data.shipmentReference,
          //       });
          //     } else {
          //       this.$message.error(
          //         "Please select a single row to view the file"
          //       );
          //     }
          //   },
          // },
          "separator",
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let filter = this.gridApi.getFilterModel();
                if (filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value],
                  };
                }
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = ["copy", "copyWithHeaders", "export", "chartRange"];
      }

      return result;
    },
    openFilesActive() {
      this.loadingFiles = true;
      this.loadingFiles = false;
      this.viewOpenFiles = true;
    },

    clearOpenFiles() {
      this.openFiles = [];
      this.viewOpenFiles = false;
    },

    chatColor(comments) {
      let result = "";
      if (comments.length > 0) {
        if (Object.keys(comments[0]).includes("shipmentDocumentTypeComment")) {
          //check if all the values for shipmentDocumentTypeComment are null. If all are null, then the comments are not related to the document
          let check = [];
          comments.forEach((x) => {
            if (
              x.shipmentDocumentTypeComment &&
              x.shipmentDocumentTypeComment.comment
            ) {
              check.push(x.shipmentDocumentTypeComment.comment);
            }
            if (check.length > 0) {
              result = "tertiary";
            } else {
              result = "";
            }
          });
        } else {
          result = "tertiary";
        }
      } else {
        result = "";
      }
      return result;
    },

    refresh() {
      this.getDocs();
      this.loadingFiles = true;
      this.getCounts();
      this.loadingCounts = true;
      this.refreshing = true;
      this.getDocUsers();
      setTimeout(() => {
        this.refreshing = false;
      }, 500);
    },
    refreshTable() {
      this.getDocs();
      this.gridApi.refreshCells({ force: true });
    },
    pinUser() {
      if (this.pinnedUsers.some((x) => x.id == this.selectedUser.id)) {
        this.pinnedUsers = this.pinnedUsers.filter(
          (x) => x.id != this.selectedUser.id
        );
      } else if (this.pinnedUsers.length < 3) {
        this.pinnedUsers.push(this.selectedUser);
      }
      sessionStorage.setItem("pinnedUsers", JSON.stringify(this.pinnedUsers));
    },
    postProcessPopup(params) {
      if (params.type !== "columnMenu") {
        return;
      }
      const ePopup = params.ePopup;
      ePopup.style.top = "65px";
      ePopup.style.left =
        params.column.actualWidth / 2 + params.mouseEvent.left + "px";
    },
    async resetFilters() {
      this.gridApi.setFilterModel({});
      // await this.unselectAll();
    },
    async bulkUpdateDates() {
      let response = 0;
      if (this.selectAllCheck) {
        let result = await this.agreements();
        response = result;
      } else {
        response = 200;
      }
      if (response == 200 && this.agreed == false) {
        let obj = {};
        this.bulkUpdateLoading = true;
        if (
          this.bulkUpdateArray.length > 0 &&
          this.bulkUpdateType == "Pending"
        ) {
          obj = {
            submittedDate: this.bulkDate,
            shipmentDocumentIds: this.bulkUpdateArray.map((x) => x.id),
            shipmentId: this.bulkUpdateArray.map((x) => x.shipmentId),
            type: "Pending",
          };
        } else {
          obj = {
            completedDate: this.bulkDate,
            shipmentDocumentIds: this.bulkUpdateArray.map((x) => x.id),
            shipmentId: this.bulkUpdateArray.map((x) => x.shipmentId),
            type: "Submitted",
          };
        }
        console.log(obj);
        try {
          let res = await this.$API.bulkUpdateShipmentDocuments(obj);
          this.$message.success("Successfully updated!");
          this.bulkUpdateModal = false;
          this.bulkUpdateLoading = false;
          this.refresh();
          this.bulkUpdateArray = [];
        } catch (e) {
          this.$message.error("An error ocurred");
          this.bulkUpdateArray = [];
          this.bulkUpdateLoading = false;
        }
      }
    },
    async filterFromOpenFiles(item, field) {
      if (item && field) {
        await this.resetFilters();
        await this.addToFilter(item.fileNumber, "fileNumber");
        this.switchFilter(field);
        this.viewOpenFiles = false;
        console.log(item, field);
      }
    },

    async agreements() {
      // I, Zenon, copied this section of code, so whatever the comments are below, I don't know what they are for
      return new Promise((resolve, reject) => {
        if (this.selectAll) {
          this.userAgreement = true;
          const unwatch = this.$watch("agreed", (newVal) => {
            // Cleanup the watcher after it triggers
            unwatch();
            if (newVal === true) {
              // Reset for future use
              this.agreed = false;
              resolve(200);
            } else if (newVal === false) {
              // Handle disagree case if needed
              reject("User disagreed");
            }
          });
        } else {
          resolve(200);
        }
      });
    },


    async commentDoc(doc) {
      this.savingComment = true;
      if (this.commentItem && !this.commentItem.commentType) {
        this.commentItem.commentType = "Query";
      }
      let comment = {
        shipmentDocumentTypeId: doc.id,
        comment: this.commentItem.comment,
        commentType: this.commentItem.commentType,
        shipmentId: doc.shipmentId,
        createdById: this.$store.state.user.id,
      };
      let res = await this.$API.addShipmentDocumentTypeComment(comment);
      if (res) {
        comment.createdAt = res.createdAt;
        comment.user = this.$store.state.user;
        comment.shipmentDocumentTypeComment = {
          comment: this.commentItem.comment,
          commentType: this.commentItem.commentType,
        };
        this.files = this.files.map((x) => {
          if (x.id == doc.id) {
            x.comments.push(comment);
          }
          return x;
        });
      } else {
        this.$message({
          type: "error",
          message: `Message could not go through. Please check your connection and try again.`,
        });
      }
      // if (res && this.commentItem.commentType == 'General' || this.commentItem.commentType == 'Invoice') {
      //   await this.getDocumentLog(this.documentItem.id)
      // }
      this.commentItem = {};
      this.savingComment = false;
      this.commentModal = false;
    },
    async viewShipment(item) {
      this.$router.push({
        path: "/documentation/" + item.shipmentReference,
      });
    },
    async getDocUsers() {
      let users = await this.$API.getDocUsers();
      if (users) {
        this.docUsers = users;
        sessionStorage.getItem("pinnedUsers")
          ? (this.pinnedUsers = JSON.parse(
              sessionStorage.getItem("pinnedUsers")
            ))
          : (this.pinnedUsers = []);
      }
    },
    clearAllFilter() {
      this.gridApi.setFilterModel({});
    },
    filterListener(event) {
      let filter = this.gridApi.getFilterModel();
      this.filterActive = Object.keys(filter).length > 0;
      this.filterSetting = filter;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.addEventListener("filterChanged", this.filterListener);
      this.getDocs();
    },

    async getShipmentDocumentLog(id) {
      // console.log('getShipmentDocumentLog called with ID:', id);
      this.loadingComments = true;

      try {
        // console.log('Making API call to fetch comments for ID:', id);
        let comments = await this.$API.getShipmentDocumentTypeComments(id);

        // console.log('API response for comments:', comments);

        this.chatOpen = id;
        comments = JSON.parse(JSON.stringify(comments));
        console.log("Processed comments data:", comments);

        if (comments) {
          this.files = this.files.map((file) => {
            if (file.id === id) {
              // console.log('Assigning comments to file with ID:', file.id);
              file.comments = comments;
              // console.log('Updated file:', file);
            }
            return file;
          });
        }
      } catch (error) {
        console.error("Error fetching comments:", error);
      } finally {
        this.loadingComments = false;
        console.log("Loading comments set to false");
      }
    },

    async getCounts() {
      if (this.countsTimeout) {
        clearTimeout(this.countsTimeout);
      }
      if (this.loadingCounts && this.countsRequest) {
        this.countsRequest.abort();
      }
      this.countsTimeout = setTimeout(async () => {
        this.countsRequest = new AbortController();
        const signal = this.countsRequest.signal;
        this.loadingCounts = true;
        let [readyFiles, notReadyInvoice] = await Promise.allSettled([
          this.$API.getReadyFileCount({ userId: this.selectedUser.id }, signal),
          this.$API.notReadyToInvoiceCount(
            { userId: this.selectedUser.id },
            signal
          ),
        ]);
        this.readyFiles = readyFiles.value;
        this.notReadyInvoice = notReadyInvoice.value;
        this.loadingCounts = false;
      }, 1000);
    },
    async changeUser(user) {
      this.selectedUser = user;
      this.userModal = false;
      this.getDocs();
      this.getCounts();
      this.updateOnSelectedKey++;
      this.userRefreshKey++;
      sessionStorage.setItem("selectedUser", JSON.stringify(user));
    },
    filterSpecific(type) {
      console.log(type);
    },
    onStateUpdated(state) {
      localStorage.setItem(
        "documentWorkspace_" + this.$store.state.currentOrg.id,
        JSON.stringify(state.state)
      );
    },
    resetTable() {
      this.gridApi.setFilterModel(null);
      this.gridApi.setRowGroupColumns([]);
      this.gridApi.applyColumnState({ state: this.columns });
      this.gridApi.refreshCells({ force: true });
    },
    switchFilter(filter) {
      if (["Arriving"].includes(filter)) {
        let filterUpdate = this.gridApi.getFilterModel();
        filterUpdate["arriving"] = {
          values: ["True"],
        };
        this.gridApi.setFilterModel(filterUpdate);
      } else if (["5+", "3-4", "0-2", "past"].includes(filter)) {
        // this.fivePlus = filter === "fivePlus" ? !this.fivePlus : false;
        // this.threeToFour = filter === "threeToFour" ? !this.threeToFour : false;
        // this.zeroToTwo = filter === "zeroToTwo" ? !this.zeroToTwo : false;
        // this.past = filter === "past" ? !this.past : false;
        // this.zeroToThree = filter === "zeroToThree" ? !this.zeroToThree : false;
        let filterUpdate = this.gridApi.getFilterModel();
        filterUpdate["sailingDayCategory"] = {
          values: [filter],
        };
        this.gridApi.setFilterModel(filterUpdate);
      } else if (["OVERDUE", "DANGER", "GOOD"].includes(filter)) {
        let filterUpdate = this.gridApi.getFilterModel();
        filterUpdate["kpiCategory"] = {
          values: [filter],
        };
        console.log("Setting filter", filterUpdate);
        this.gridApi.setFilterModel(filterUpdate);
      } else if (["rejected"].includes(filter)) {
        let filterUpdate = this.gridApi.getFilterModel();
        filterUpdate["status"] = {
          values: ["Rejected"],
        };
        console.log("Setting filter", filterUpdate);
        this.gridApi.setFilterModel(filterUpdate);
      } else if (
        [
          "openFilesFilter",
          "rejected",
          "overdue",
          "inDanger",
          "withinKPI",
        ].includes(filter)
      ) {
        this.openFilesFilter =
          filter === "openFilesFilter" ? !this.openFilesFilter : false;
        this.rejected = filter === "rejected" ? !this.rejected : false;
        this.overdue = filter === "overdue" ? !this.overdue : false;
        this.inDanger = filter === "inDanger" ? !this.inDanger : false;
        this.withinKPI = filter === "withinKPI" ? !this.withinKPI : false;
      }
      [
        "fivePlus",
        "threeToFour",
        "zeroToTwo",
        "past",
        "zeroToThree",
        "openFilesFilter",
        "rejected",
        "overdue",
        "inDanger",
        "withinKPI",
      ].forEach((x) => {
        sessionStorage.setItem(x, this[x]);
      });
    },

    async getDocs() {
      if (this.fileTimeout) {
        clearTimeout(this.fileTimeout);
      }
      if (this.loadingFiles && this.fileRequest) {
        this.fileRequest.abort();
      }
      this.loadingFiles = true;
      if(this.gridApi){
      this.gridApi.setGridOption("loading", true);
      }
      this.fileTimeout = setTimeout(async () => {
        this.fileRequest = new AbortController();
        const signal = this.fileRequest.signal;
        this.loadingFiles = true;
        let result = await this.$API.getAllDocumentsForOrg(
          {
            userId: this.selectedUser.id,
            documentationStatus: ["COMPLETED", "OPEN"],
            showCompleted: this.showCompleted,
          },
          signal
        );
        this.files = result.documents;
        this.shipmentCounts = result.shipmentCounts;
      this.gridApi.setGridOption("loading", false)
        let uniqueFiles = [...new Set(this.files.map((x) => x.fileNumber))];
        let openFiles = [];
        for (let i = 0; i < uniqueFiles.length; i++) {
          let file = this.files.filter((x) => x.fileNumber == uniqueFiles[i]);
          let obj = {
            index: i + 1,
            assignedDocumentUser: file[0].assignedDocumentUser,
            assignedDocumentUserId: file[0].assignedDocumentUserId,
            fileNumber: uniqueFiles[i],
            shipment: file[0].shipment,
            shipperName: file[0].shipperName,
            shipper: file[0].shipper,
            consigneeName: file[0].consigneeName,
            consignee: file[0].consignee,
            vesselVoyage: file[0].vesselVoyage,
            vessel: file[0].vessel,
            customerName: file[0].customerName,
            customer: file[0].customer,
            etd: file[0].etd,
            eta: file[0].eta,
            rejected: file.filter((x) => x.status == "Rejected").length,
            overdue: file.filter((x) => x.kpiCategory == "OVERDUE").length,
            inDanger: file.filter((x) => x.kpiCategory == "DANGER").length,
            withinKPI: file.filter((x) => x.kpiCategory == "GOOD").length,
          };
          openFiles.push(obj);
        }
        this.openFiles = openFiles;
        this.loadingFiles = false;
      }, 250);
    },

    setFilter(data) {
      this.gridApi.setFilterModel(data);
      this.viewOpenFiles = false;
    },
    showCompletedTrigger() {
      this.showCompleted = !this.showCompleted;
      this.getDocs();
    },
    sumDocument(type, value) {
      if (this.files) {
        let filter = [
          ...new Set(this.files.filter((x) => x[type] && x[type] == value)),
        ];
        return filter.length;
      } else {
        return 0;
      }
    },
    sumFiles(type) {
      if (this.files) {
        if (type != "Arriving") {
          let filter = [
            ...new Set(
              this.files
                .filter((x) => x.sailingDayCategory == type)
                .map((x) => x.fileNumber)
            ),
          ];
          return filter.length;
        } else {
          let filter = [
            ...new Set(
              this.files.filter((x) => x.arriving).map((x) => x.fileNumber)
            ),
          ];
          return filter.length;
        }
      } else {
        return 0;
      }
    },
    // async updateKpi(document){
    // let result = await this.$API.getShipmentDocumentTypeDetail(document.id)

    // return result
    // },
    async viewDocument(document) {
      console.log(document);
      if (document) {
        this.documentLoading = document.id;
        this.loadingDocumentDetail = true;
        this.documentModal = true;

        let [doc, documentTasks, documentUploads, actions, shipment, bookings] =
          await Promise.allSettled([
            this.$API.getShipmentDocumentTypeDetail(document.id),
            this.$API.getShipmentDocumentTasks(document.id),
            this.$API.getShipmentDocuments(document.shipment.id),
            this.$API.getShipmentActions(document.shipment.id),
            this.$API.getSingleShipment(document.shipment.shipmentReference),
            this.$API.getShipmentBookings(document.shipment.shipmentFileId),
          ]);
        this.document = doc.value;
        if (this.document.variations) {
          this.documentTypes = [this.document];
        }
        this.documentTasks = documentTasks.value;
        this.documentUploads = documentUploads.value;
        this.actions = actions.value;
        this.shipment = shipment.value;
        this.bookings = bookings.value;
        this.fileNumber = this.shipment.shipmentFile.fileNumber;
        setTimeout(() => {
          this.openKey++;
        }, 250);
        this.loadingDocumentDetail = false;
        this.documentLoading = null;
      }
    },
  },
};
</script>
    
    <style scoped>
.primaryText--text :is(.v-chip .v-avatar.smallerAvatar) {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
}

.smallerAvatar {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
}

.searchInput :is(.v-input__append-inner) {
  margin-top: 2px !important;
}
.document {
  text-decoration: underline;
}
.rejected {
  color: var(--v-danger-base) !important;
}
.overdue {
  color: var(--v-redPop-lighten1) !important;
}
.inDanger {
  color: #dfaa49 !important;
}
.withinKPI {
  color: var(--v-tertiary-base) !important;
}

.chipCompleted {
  overflow: hidden;
  white-space: nowrap;
  max-width: 30px;
  font-size: 12px;
  transition: max-width 0.3s ease-in-out;
}

.chipCompleted:hover {
  max-width: 200px;
}

.filterHover {
  overflow: hidden;
  white-space: nowrap;
  max-width: 30px;
  font-size: 12px;
  transition: max-width 0.3s ease-in-out;
}

.filterHover:hover {
  max-width: 200px;
}
.textCompleted {
  visibility: hidden;
}

.bulkUpdateText {
  visibility: hidden;
}

.textFilters {
  visibility: hidden;
}

.searchChipWrap {
  width: 70%;
  display: grid;
  transition: width 0.3s ease-in-out;
}
.bulkUpdate {
  overflow: hidden;
  white-space: nowrap;
  max-width: 30px;
  font-size: 12px;
  transition: max-width 0.3s ease-in-out;
}
.bulkUpdate:hover {
  max-width: 200px;
}
.slightShrink {
  width: 65% !important;
}

.shrinkChip {
  width: 60% !important;
}
.shrinkChipPlus {
  width: 55% !important;
}
.shrinkMore {
  width: 57% !important;
}
.shrinkMorePlus {
  width: 50% !important;
}

.chipCompleted:hover .textCompleted {
  visibility: visible;
}

.filterHover:hover .textFilters {
  visibility: visible;
}

.bulkUpdate:hover .bulkUpdateText {
  visibility: visible;
}

.rotateRefresh {
  animation: rotate 0.5s linear infinite;
}
</style>
    