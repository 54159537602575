<template>
  <div>
    <v-card flat>
      <v-row class="pa-3" align="center" no-gutters>
        <v-col cols="12" sm="4" v-if="!$vuetify.breakpoint.mobile">
          <v-text-field
            hide-details
            prepend-inner-icon="search"
            placeholder="Search"
            v-model="search"
            outlined
            dense
            rounded
            clearable
          ></v-text-field>
        </v-col>
        <v-chip v-if="!loading" color="blue-grey" class="mx-1" small>
          <v-icon small left>widgets</v-icon> {{ displayData.length }} Files
        </v-chip>
        <v-chip color="red" class="mx-1" small @click="resetFilters()">
          <v-icon small left>filter_alt_off</v-icon> Clear Filters
        </v-chip>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" icon color="redPop"
          ><v-icon>close</v-icon>
        </v-btn>
      </v-row>
      <v-card-text class="my-0 py-0">
        <ag-grid-vue
          groupDisplayType="multipleColumns"
          :animateRows="false"
          :rowGroupPanelShow="'always'"
          :autoSizeStrategy="{
            type: 'fitGridWidth',
            defaultMinWidth: 125,
            columnLimits: [
              {
                colId: 'documentationStatus',
                minWidth: 120,
                width: 120,
              },
              {
                colId: 'fileNumber',
                minWidth: 180,
                width: 180,
              },
              {
                colId: 'vesselVoyage',
                minWidth: 200,
              },
              {
                colId: 'shipperName',
                minWidth: 200,
              },
              {
                colId: 'onBehalfShipperName',
                minWidth: 200,
              },
              {
                colId: 'consigneeName',
                minWidth: 200,
              },
            ],
          }"
          :style="{ width: '100%', height: '90vh' }"
          :enableRangeSelection="true"
          :suppressAggFuncInHeader="true"
          :columnDefs="columns"
          :rowData="files"
          :defaultColDef="defaultColDef"
          :getContextMenuItems="getContextMenuItems"
          :getRowId="getRowId"
          @grid-ready="onGridReady"
          @rowClicked="viewShipment"
        ></ag-grid-vue>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="previewShipmentFile"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <ViewFile
        :key="previewKey"
        v-if="previewShipmentFile && selectedFile && selectedFile.id"
        @Cancelled="cancelFile"
        :fileId="selectedFile.id"
        @close="previewShipmentFile = false"
        :allowShipmentProcess="true"
        @ProcessedFile="updateData"
      />
    </v-dialog>
    <div class="bottomBar" v-if="$vuetify.breakpoint.mobile">
      <v-text-field
        hide-details
        prepend-inner-icon="search"
        placeholder="Search"
        v-model="search"
        outlined
        dense
        rounded
        clearable
      ></v-text-field>
    </div>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import ViewFile from "../SystemComponents/PreviewFileModal.vue";
/* eslint-disable vue/no-unused-components */

import { AgGridVue } from "ag-grid-vue";
import OrganisationName from "../GridComponents/Organisation.vue";
import PortCell from "../GridComponents/Port.vue";
import UserCell from "../GridComponents/User.vue";
export default {
  components: {
    AgGridVue,
    OrganisationName,
    PortCell,
    UserCell,
    ViewFile,
  },
  props: ["isDialog", "selectedUser", "week", "filter", "opened"],
  data: () => ({
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      enableRowGroup: true,
      enableValue: true,
      enablePivot: true,
      flex: 1,
      minWidth: 100,
      cellClass: "d-flex align-center",
      cellStyle: { textAlign: "center", fontSize: "11px" },
    },
    files: [],
    filters: {},
    filteredResults: [],
    fileQuery: null,
    fileTimeout: null,
    params: {
      limit: 10,
      offset: 0,
      search: "69",
    },
    columns: [
      {
        headerName: "User",
        field: "assignedDocumentUserName",
      },
      {
        headerName: "File No.",
        field: "fileNumber",
      },
      {
        headerName: "Type",
        field: "type",
      },
      {
        headerName: "Carrier",
        field: "shippingLineName",
      },
      {
        headerName: "Vessel",
        field: "vesselVoyage",
      },
      {
        headerName: "POL",
        field: "portOfLoadValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfLoadValue : null,
            name: p.data ? p.data.portOfLoadCity : null,
          };
        },
      },
      {
        headerName: "POD",
        field: "portOfDischargeValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfDischargeValue : null,
            name: p.data ? p.data.portOfDischargeCity : null,
          };
        },
      },
      {
        headerName: "Destination",
        field: "finalDestinationValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.finalDestinationValue : null,
            name: p.data ? p.data.finalDestinationCity : null,
          };
        },
      },
      {
        headerName: "Shipper",
        field: "shipperName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shipper
            ? {
                name: p.data.shipper.name,
                alias: p.data.shipper.alias,
                logo: p.data.shipper.logo,
                friendlyName: p.data.shipper.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "Consignee",
        field: "consigneeName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.consignee
            ? {
                name: p.data.consignee.name,
                alias: p.data.consignee.alias,
                logo: p.data.consignee.logo,
                friendlyName: p.data.consignee.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "Customer",
        field: "customerName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.customer
            ? {
                name: p.data.customer.name,
                alias: p.data.customer.alias,
                logo: p.data.customer.logo,
                friendlyName: p.data.customer.friendlyName,
              }
            : null;
        },
      },
      {
        headerName: "ETD",
        field: "etd",
      },
      {
        headerName: "ETA",
        field: "eta",
      },
    ],
    gridApi: null,
    headers: [
      {
        text: "User",
        value: "assignedDocumentUserName",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Vessel",
        value: "vesselVoyage",
      },
      {
        text: "Shipper",
        value: "shipperName",
      },
      {
        text: "Consignee",
        value: "consigneeName",
      },
      {
        text: "Customer",
        value: "customerName",
      },
      {
        text: "Carrier",
        value: "shippingLineName",
      },
      {
        text: "POL",
        value: "portOfLoadValue",
      },
      {
        text: "Destination",
        value: "finalDestinationValue",
      },
      {
        text: "ETD",
        value: "etd",
      },
      {
        text: "ETA",
        value: "eta",
      },
    ],
    loading: false,
    previewShipmentFile: false,
    previewKey: 2000,
    search: null,
    selectedFile: null,
  }),
  watch: {
    opened: {
      immediate: true,
      handler(val) {
        if (val == true) {
          this.resetFilters();
          this.getFiles();
        } else {
          this.files = [];
          this.resetFilters();
        }
      },
    },
  },
  computed: {
    mobileHeaders() {
      return this.fileHeaders.filter((x) => x.value != "fileNumber");
    },
    displayData() {
      let result = this.filteredResults;
      if (this.search) {
        result = result.filter(
          (x) =>
            (x.shipperName &&
              x.shipperName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.assignedDocumentUserName &&
              x.assignedDocumentUserName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.fileNumber &&
              x.fileNumber.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.portOfLoadValue &&
              x.portOfLoadValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.portOfLoadCity &&
              x.portOfLoadCity
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.finalDestinationValue &&
              x.finalDestinationValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.finalDestinationCity &&
              x.finalDestinationCity
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.shippingLineName &&
              x.shippingLineName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.vesselVoyage &&
              x.vesselVoyage
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consigneeName &&
              x.consigneeName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.eta &&
              x.eta.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.etd && x.etd.toLowerCase().includes(this.search.toLowerCase()))
        );
      }
      return result;
    },
  },
  mounted() {
    if (!this.opened) {
      this.resetFilters();
      this.getFiles();
    }
  },
  methods: {
    cancelFile(id) {
      this.files = this.files.filter((x) => x.id != id);
      let rownNode = this.gridApi.getRowNode(id);
      if (rownNode) {
        rownNode.setData(null);
      }
      this.previewShipmentFile = false;
    },

    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    getRowId(params) {
      return params.data.id;
    },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
        {
            name: "View Shipment",
            icon: '<span class="ag-icon ag-icon-arrows" unselectable="on" role="presentation"></span>',
            action: () => {
              if (
                params.node &&
                params.node.data &&
                params.node.data.id
              ) {
                this.viewShipment(params.node.data);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = ["copy", "copyWithHeaders", "export", "chartRange"];
      }

      return result;
    },
    async getFiles() {
      this.loading = true;
      let obj = {};
      if (this.selectedUser && this.selectedUser.id) {
        obj.userId = this.selectedUser.id;
      }
      let filterFinal = null;
      // let {vesselVoyage, ...filterVessel} = this.filter
      if (
        this.filter &&
        this.filter.vesselVoyage &&
        this.filter.vesselVoyage?.voyage
      ) {
        filterFinal = {
          ...this.filter,
          vesselVoyage: {
            voyage: this.filter.vesselVoyage.voyage,
            id: this.filter.vesselVoyage.id,
          },
        };
      } else {
        filterFinal = { ...this.filter };
      }
      if (filterFinal) {
        obj = { ...obj, ...filterFinal };
      }
      if (this.week) {
        obj.week = this.week;
      }
      if (this.isDialog) {
        this.files = await this.$API.notReadyToInvoice({ params: obj });
      } else {
        this.files = await this.$API.notReadyToInvoice({});
      }
      this.loading = false;
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
    resetFilters() {},

    viewShipment(file) {
      let reference = file.data ? file.data.shipmentReference : file.shipmentReference
      this.$router.push({
        path: "/documentation/" + reference
      });
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 720px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomBar {
  height: 3rem;
  width: 100dvw;
  position: fixed;
  display: flex;
  padding: 0.2rem 0.5rem;
  bottom: 0;
  background-color: var(--v-greyRaised-base) !important;
  align-content: center;
}

.mobileToolbarCard :is(.v-toolbar__content) {
  padding: 5px !important;
}
</style>